import { Button, Font } from "@/design-system";
import { BottomSheet } from "@/global-components/components";
import useGetPlatform from "@/hooks/useGetPlatform";
import useMixpanel from "@/hooks/useMixpanel";
import Image from "next/image";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import { H1 } from "../../../design-system/Heading";
import DesktopDownloadBottomSheet from "../DesktopDownloadBottomSheet";

const IMAGE_RATIO = 270 / 375;

const TopSection = () => {
  const { platform } = useGetPlatform();
  const { track } = useMixpanel();

  const renderDownloadButton = () => {
    if (platform === "ANDROID") {
      return (
        <Button.Large
          level="primary"
          text="앱 다운로드"
          iconProps={{
            iconName: "googlePlay",
            position: "leading",
            color: "white",
          }}
          onPress={() => {
            track("[Click] Download CTA on Home", {
              "Button Type": "Hero Section",
            });
            window.open("https://my-doctor.app.link/sNIjxvg7uHb", "_blank");
          }}
          style={{
            width: "fit-content",
          }}
        />
      );
    }
    if (platform === "IOS") {
      return (
        <Button.Large
          level="primary"
          text="앱 다운로드"
          iconProps={{
            iconName: "apple",
            position: "leading",
            color: "white",
          }}
          onPress={() => {
            track("[Click] Download CTA on Home", {
              "Button Type": "Hero Section",
            });
            window.open("https://my-doctor.app.link/sNIjxvg7uHb", "_blank");
          }}
          style={{
            width: "fit-content",
          }}
        />
      );
    }
    if (platform === "DESKTOP") {
      return (
        <Button.Large
          level="primary"
          text="앱 다운로드"
          iconProps={{
            iconName: "qr",
            position: "leading",
            color: "white",
          }}
          onPress={() => {
            track("[Click] Download CTA on Home", {
              "Button Type": "Hero Section",
            });
            BottomSheet.show({
              children: (
                <DesktopDownloadBottomSheet quickLink="https://my-doctor.app.link/sNIjxvg7uHb" />
              ),
            });
          }}
          style={{
            width: "fit-content",
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <Container>
      <TextContainer>
        <H1 fontType="display2" textAlign="center">
          {`대한민국 대표\n비대면 진료 • 약 배달 어플\n나만의닥터`}
        </H1>
        <Font type="body3" color="medium" style={{ textAlign: "center" }}>
          {`2022 대한민국소비자브랜드 대상 (서비스 만족도 1위)\n한국소비자글로법협의회 주관 / 한국소비자평가원 조사·평가`}
        </Font>
      </TextContainer>
      <ButtonContainer>{renderDownloadButton()}</ButtonContainer>
      <ImageContainer>
        <Image
          src={"/home/homeTop.png"}
          width={560}
          height={560 * IMAGE_RATIO}
          alt="bg-top"
          priority={true}
        />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${DEFAULT_PADDING * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.div`
  padding: ${DEFAULT_PADDING}px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.span`
  width: 100%;

  span {
    width: 100% !important;
  }
`;

export default TopSection;
